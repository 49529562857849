'use client';
import React, { useRef } from 'react'
import {Mesh, MeshStandardMaterial} from 'three'
import { useGLTF} from "@react-three/drei";
import { GLTF } from "three-stdlib";
import { useSpring, animated } from '@react-spring/three';
import { GroupProps, useFrame } from '@react-three/fiber';
interface ModelProps extends GroupProps {
  active: boolean;
}

export type DreiGLTF = GLTF & {
  nodes: {
    Cube084: Mesh;
    Cube085: Mesh;
    Cube074: Mesh;
    Cube086: Mesh;
    Cube087: Mesh;

    Text038: Mesh;
    Text039: Mesh;
  };
  materials: {
    ["Material"]: MeshStandardMaterial;
    ["Material.008"]: MeshStandardMaterial;
    ["Material.006"]: MeshStandardMaterial;
    ["Material.005"]: MeshStandardMaterial;
    ["Material.007"]: MeshStandardMaterial;
    ["Gold"]: MeshStandardMaterial;
    ["Material.004"]: MeshStandardMaterial;
    ["Material.003"]: MeshStandardMaterial;
    ["Material.001"]: MeshStandardMaterial;
  };
};

export function Model({ active, ...props }: ModelProps) {

  const { nodes, materials } = useGLTF('/images/3d.glb') as DreiGLTF;
  const groupRef = useRef<THREE.Group | null>(null);
  const cubeRef = useRef<THREE.Group>(null);

  const { rotation } = useSpring({
    to: { rotation: active ? [0, 0, 0]: [0, -1, 0] },
    from: { rotation: [0, -1, 0]},
    config: { mass: 1, tension: 180, friction: 50 }
  });

  const {position} = useSpring({
    to: { position: active ? [0.1,0,0.2] : [0.1,0,0]},
    from: {position: [0.1, 0, 0]},
    config: { mass: 1, tension: 180, friction: 50}
  })

  useFrame(() => {
    const [x, y, z] = rotation.get();
    const [posX, posY, posZ] = position.get();
    if (groupRef.current) {
      groupRef.current.rotation.set(x, y, z);
      groupRef.current.position.set(posX, posY, posZ);
    }
  });

  useFrame((state) => {
    const elapsedTime = state.clock.getElapsedTime();
    if (cubeRef.current) {
      cubeRef.current.rotation.x = elapsedTime * 0.5; // Adjust speed by changing multiplier
      cubeRef.current.rotation.z = elapsedTime * 0.5; // Same as above
    }
  });
  
  return (
    <animated.group {...props} scale={1} dispose={null}
      ref={groupRef} 
    >
      <group
        ref={cubeRef}
        position={[0, -0.05, 0.055]}
        rotation={[0, 0, 0]}
        scale={0.3}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube084.geometry}
          material={materials["Gold"]}
        />
      </group>

      <group
        position={[0, 0, 0]}
        rotation={[0, 0, 0]}
        scale={0.2}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube085.geometry}
        ><meshBasicMaterial color="white" /></mesh>
      </group>

      <group
        position={[0, 0, 0]}
        rotation={[0, 0, 0]}
        scale={0.2}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube074.geometry}
          material={materials["Material.004"]}
        />
      </group>

      <group
        position={[0, 0, 0]}
        rotation={[0, 0, 0]}
        scale={0.2}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube087.geometry}
          material={materials["Material.006"]}
        />
      </group>
      
      
    {/* TEXT */}
      <group
        position={[0, 0, 0]}
        rotation={[0, 0, 0]}
        scale={0.2}
      ><mesh
          castShadow
          receiveShadow
          geometry={nodes.Text038.geometry}
          material={materials["Material.006"]}
        />
      </group>

      <group
        position={[0, 0, 0]}
        rotation={[0, 0, 0]}
        scale={0.2}
      ><mesh
          castShadow
          receiveShadow
          geometry={nodes.Text039.geometry}
          material={materials["Material.006"]}
        />
      </group>

    </animated.group>
  );
}




export default Model;
