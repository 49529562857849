'use client'

import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Vector3, Euler } from 'three';
import { Canvas, useFrame, useThree } from '@react-three/fiber'
import { debounce } from '@/utils/hooks';

import UIModel from './ui';
import VIModel from './vi';
import DevModel from './dev';
import classes from './canvas.module.scss'
import Lights from '@/components/canvas/Lights';
import Card3d from './card-3d';

function isTouchDevice() {
    return (('ontouchstart' in window) ||
       (navigator.maxTouchPoints > 0))
    }

export default function ServicesCanvas() {

    const itemPositions = [-0.35, -.14, .1, .33];
    const [camZPos, setCamZPos] = useState(1.1);
    const [activeElement, setActiveElement] = useState(0);
    const [width, setWidth] = useState(0);
    const [startX, setStartX] = useState(0);
    const wrapperRef = useRef<HTMLDivElement>(null);
    const [touchDevice, setTouchDevice] = useState(false);

    const updateCanvasSize = useCallback(() => {
        const height = width < 1023 ? '550px' : `${800 * (width / 2500)}px`;
        if (wrapperRef.current) {  // Check if the ref.current is not null
            wrapperRef.current.style.width = '100%';
            wrapperRef.current.style.height = height;
        }
    }, [width]);

    useEffect(() => {
        setWidth(window.innerWidth);
        if (width < 1023) {
            setCamZPos(1.25);
        } else if (width < 600) {
            setCamZPos(1.4)
        } else {
            setCamZPos(1.1);
        }
        setTouchDevice(isTouchDevice());
    }, [])

    useEffect(() => {
        updateCanvasSize();  // Call initially to set up
        const handleResize = debounce(updateCanvasSize, 300);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [updateCanvasSize]);

    const handleTouchStart : React.TouchEventHandler<HTMLDivElement> = (e) => {
        const touch = e.touches[0];
        setStartX(touch.clientX);
    };

    const handleTouchEnd : React.TouchEventHandler<HTMLDivElement> = (e) => {
        const touch = e.changedTouches[0];
        const endX = touch.clientX;
        if (startX - endX > 50) {
            // Swiped left
            if (activeElement === 3) {
                
            } else {
                const newIndex = activeElement === itemPositions.length - 1 ? 0 : activeElement + 1;
                setActiveElement(newIndex);
            }
        } else if (startX - endX < -50) {
            // Swiped right
            if (activeElement === 0) {
                
            } else {
                const newIndex = activeElement === 0 ? itemPositions.length - 1 : activeElement - 1;
                setActiveElement(newIndex);
            }
        }
    };
    
    const CameraAnimator = () => {
        const { camera } = useThree();
        useFrame(() => camera.position.lerp(new Vector3(width > 1024 ? 0 : itemPositions[activeElement], camera.position.y, camZPos), 0.05));
        return null;
    };
    
    //debug: not the issue with mobile swipe
    const handleModelClick = useCallback((index: number) => {
        if (!touchDevice) {
            setActiveElement(index);
        }
    }, [width]);

    return (
        <>
            <div ref={wrapperRef} className={classes['services-wrapper']}
                onTouchStart={handleTouchStart}
                onTouchEnd={handleTouchEnd}
            >
                <Canvas
                    className={classes['canvas']}
                    camera={{ position: new Vector3(0, 0, 1.1), rotation: new Euler(0, 0, 0), fov: 24 }}>
                    <Lights />
                    <CameraAnimator />
                        <DevModel
                            active={activeElement === 0}
                            onPointerDown={() => handleModelClick(0)}
                            onPointerOver={() => handleModelClick(0)}
                            castShadow
                            receiveShadow
                        />
                        <UIModel
                            active={activeElement === 1}
                            onPointerDown={() => handleModelClick(1)}
                            onPointerOver={() => handleModelClick(1)}
                            castShadow
                            receiveShadow
                        />
                        <Card3d
                            active={activeElement === 2}
                            onPointerDown={() => handleModelClick(2)}
                            onPointerOver={() => handleModelClick(2)}
                            castShadow
                            receiveShadow
                        />
                        <VIModel
                            active={activeElement === 3}
                            onPointerDown={() => handleModelClick(3)}
                            onPointerOver={() => handleModelClick(3)}
                            castShadow
                            receiveShadow
                        />
                </Canvas>
            </div>
        </>
    )
}