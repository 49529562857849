'use client';

export default function Lights() {

  return (
    <>
      <ambientLight intensity={0.25} />
      <pointLight castShadow position={[0, 1, 0]} intensity={1} />
      <hemisphereLight intensity={10} color="#ffffff" groundColor="black" />
      <directionalLight  
        position={[0, 5, 1]} 
        intensity={10} 
        castShadow
        shadow-mapSize={[1024, 1024]} 
        shadow-bias={0.1} 
      />
    </>
  )
}
