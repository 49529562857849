import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/home.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/canvas/services-canvas.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/case-studies/case-studies.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/content/content.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Footer"] */ "/vercel/path0/src/components/footer/footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/hero/hero.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/services/services.module.scss");
