type Func = (...args: any[]) => void;

interface DebouncedFunction extends Func {
    cancel: () => void;
}

export const debounce = (func: Func, wait: number): DebouncedFunction => {
    let timeout: ReturnType<typeof setTimeout> | null = null;

    const debouncedFunction: DebouncedFunction = function(...args: any[]) {
        const later = () => {
            timeout = null;
            func(...args);
        };

        if (timeout !== null) {
            clearTimeout(timeout);
        }
        timeout = setTimeout(later, wait);
    };

    debouncedFunction.cancel = function() {
        if (timeout !== null) {
            clearTimeout(timeout);
            timeout = null;
        }
    };

    return debouncedFunction;
}



