
'use client';
import React, {useRef, useEffect} from 'react';
import classes from "./case-studies.module.scss";
import gsap from "gsap";
import ScrollTrigger from 'gsap/ScrollTrigger';
import cx from 'classnames';
import Link from 'next/link';

export type StudyProps = {
    name?: string,
    location?: string,
    client?: string,
    tools?: string[],
    categories?: string[],
    link?: string,
}

export type CaseStudiesProps = {
    className?: string,
    studies?: StudyProps[]
}


export default function CaseStudies(props: CaseStudiesProps) {

    const _classes = cx(classes['case-studies'], {
    }, props.className);

    const wrapperRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);

        if (!wrapperRef.current) return;

        gsap.fromTo(wrapperRef.current, 
            { opacity: 0, y: 10 }, 
            {
                opacity: 1, y: 0,
                delay: 0,
                duration: 1,
                scrollTrigger: {
                    trigger: wrapperRef.current,
                    start: "top-=800",
                    end: "bottom bottom",
                }
            }
        );
        return () => {
            ScrollTrigger.getAll().forEach(trigger => trigger.kill());
        };
    });

    return (
        <div className={_classes} ref={wrapperRef}>
            <div className={classes['projects']}>
                {
                props.studies && props.studies.map((study, index) => {
                    return (
                        <Link scroll={true} href={study.link ? study.link : ''} className={classes['study']} key={index}>
                            <div className={classes['study-header']}>
                                <div className={classes['study-name']}>{study.name} <div className={classes['view-project']}>View Project</div></div>
                                <div className={classes['study-categories']}>
                                    {
                                    study.categories ?
                                    study.categories.map((category, index) => (
                                        <div className={classes['category']} key={index}>
                                            {category}
                                        </div>
                                    ))
                                    : null
                                    }
                                </div>
                            </div>
                        </Link>
                    )
                })
                }
            </div>
        </div>
    );
}

