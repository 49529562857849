'use client';
import React from 'react';
import classes from './footer.module.scss';
import Marquee from "react-fast-marquee";
import Pointer from '@/assets/images/pointer.svg';
import Link from 'next/link';

export const Footer = () => {

  return (
    <div className={classes['footer']}>
        <div className={classes['content-area']}>
            <Link href="/contact">
              <Marquee className={classes['contact']}>
                  WORK WITH ME <Pointer className={classes['pointer']} /> WORK WITH ME <Pointer className={classes['pointer']} /> WORK WITH ME <Pointer className={classes['pointer']} /> 
              </Marquee>
            </Link>
        </div>
    </div>
  );
};
