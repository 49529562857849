'use client';
import React, {useRef, useEffect} from 'react';
import gsap from "gsap";
import ScrollTrigger from 'gsap/ScrollTrigger';
import classes from "./hero.module.scss";

export default function Hero() {
    const wrapperRef = useRef<HTMLDivElement>(null);
    const marcusRef = useRef<HTMLDivElement>(null);
    const hughRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);

        if (!marcusRef.current || !hughRef.current) return;

        // Initial slide-in animations
        gsap.fromTo(marcusRef.current, 
                    { xPercent: 15 }, 
                    { xPercent: 1, opacity: 1, duration: 2, ease: "power4.inOut" });
        gsap.fromTo(hughRef.current, 
                    { xPercent: -15 }, 
                    { xPercent: -2, opacity: 1, duration: 2, ease: "power4.inOut" });

        // Wait for the initial animations to complete before setting up ScrollTrigger
        gsap.timeline({
            scrollTrigger: {
                trigger: wrapperRef.current,
                start: "top top",
                end: () => `+=${document.body.scrollHeight}`,
                onUpdate: (self) => {
                    const progress = self.progress;
                    const marcusTranslate = gsap.utils.mapRange(0, 1, 1, -15, progress);
                    const hughTranslate = gsap.utils.mapRange(0, 1, -2, 15, progress);
                    gsap.to(marcusRef.current, { xPercent: marcusTranslate, immediateRender: false });
                    gsap.to(hughRef.current, { xPercent: hughTranslate, immediateRender: false });
                }
            }
        });

        return () => {
            ScrollTrigger.getAll().forEach(trigger => trigger.kill());
        };
    }, []);

  return (
    <div ref={wrapperRef} className={classes['title-wrapper']}>
      <h1 ref={marcusRef} className={classes['hero-title']}>
        MARCUS
      </h1>
      <div className={classes['occupation-title']}>Web Developer & Designer</div>
      <h1 ref={hughRef} className={classes['hero-title-secondary']}>
        HUGH
      </h1>
    </div>
  );
}
