'use client';
import React, { useRef, useEffect, useState } from 'react'
import {Mesh, MeshStandardMaterial, Color, Material} from 'three'
import { useGLTF} from "@react-three/drei";
import { GLTF } from "three-stdlib";
import { useSpring, animated } from '@react-spring/three';
import { GroupProps, useFrame } from '@react-three/fiber';

interface ModelProps extends GroupProps {
  active: boolean;
}

type ColorState = {
  [key: string]: THREE.Color;
};

export type DreiGLTF = GLTF & {
  nodes: {
    Cube073: Mesh;
    Cube074: Mesh;
    Cube075: Mesh;
    Cube076: Mesh;
    Cube077: Mesh;
    Cube079: Mesh;
    Cube083: Mesh;

    Text034: Mesh;
    Text035: Mesh;
  };
  materials: {
    ["Material"]: MeshStandardMaterial;
    ["Material.015"]: MeshStandardMaterial;
    ["Material.011"]: MeshStandardMaterial;
    ["Material.008"]: MeshStandardMaterial;
    ["Material.006"]: MeshStandardMaterial;
    ["Material.005"]: MeshStandardMaterial;
    ["Material.007"]: MeshStandardMaterial;
    ["Gold"]: MeshStandardMaterial;
    ["Material.004"]: MeshStandardMaterial;
    ["Material.003"]: MeshStandardMaterial;
    ["Material.002"]: MeshStandardMaterial;
    ["Material.001"]: MeshStandardMaterial;
  };
};

export function Model({ active, ...props }: ModelProps) {

  const { nodes, materials } = useGLTF('/images/design.glb') as DreiGLTF;
  const groupRef = useRef<THREE.Group | null>(null);

  const highlightColor = new Color('#dea712');
  const normalColor = new Color('grey');

  const [colors, setColors] = useState<ColorState>({
    Cube076: normalColor,
    Cube077: normalColor,
    Cube079: normalColor,
    Cube083: normalColor
  });

  const [highlightedIndex, setHighlightedIndex] = useState(0);
  const elements = [nodes.Cube076, nodes.Cube077, nodes.Cube079, nodes.Cube083];

  const { rotation } = useSpring({
    to: { rotation: active ? [0, 0, 0]: [0, -1, 0] },
    from: { rotation: [0, -1, 0]},
    config: { mass: 1, tension: 180, friction: 50 }
  });

  const {position} = useSpring({
    to: { position: active ? [-.14,0,0.2] : [-.14,0,0]},
    from: {position: [-.14, 0, 0]},
    config: { mass: 1, tension: 180, friction: 50}
  })

  useFrame(() => {
    const [x, y, z] = rotation.get();
    const [posX, posY, posZ] = position.get();
    if (groupRef.current) {
      groupRef.current.rotation.set(x, y, z);
      groupRef.current.position.set(posX, posY, posZ);
    }
  });

  useEffect(() => {
    const interval = setInterval(() => {
      const newColors: ColorState = {};
      elements.forEach((element, index) => {
        newColors[element.name] = index === (highlightedIndex + 1) % elements.length ? highlightColor : normalColor;
      });
      setColors(newColors);
      setHighlightedIndex(prevIndex => (prevIndex + 1) % elements.length);
    }, 2000);
    return () => clearInterval(interval);
  }, [highlightedIndex]);

  useFrame(() => {
    elements.forEach((element, index) => {
      const material = element.material as MeshStandardMaterial;
      if (material && 'color' in material) {
        material.color.lerp(colors[element.name], 0.05);
      }
    });
  });
  
  return (
    <animated.group {...props} scale={1} dispose={null}
      ref={groupRef} 
    >
      <group
        position={[0, 0, 0]}
        rotation={[0, 0, 0]}
        scale={0.2}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube073.geometry}
          // material={materials["Material.004"]}
        >
          <meshBasicMaterial color="white" />
          </mesh>
      </group>

      <group
        position={[0, 0, 0]}
        rotation={[0, 0, 0]}
        scale={0.2}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube074.geometry}
          material={materials["Material.004"]}
        />
      </group>

      <group
        position={[0, 0, 0]}
        rotation={[0, 0, 0]}
        scale={0.2}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube075.geometry}
          material={materials["Material.015"]}
        />
      </group>

      <group
        position={[0, 0, 0]}
        rotation={[0, 0, 0]}
        scale={0.2}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube076.geometry}
          material={materials["Material.001"]}
        />
      </group>

      <group
        position={[0, 0, 0]}
        rotation={[0, 0, 0]}
        scale={0.2}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube077.geometry}
          material={materials["Material.005"]}
        />
      </group>

      <group
        position={[0, 0, 0]}
        rotation={[0, 0, 0]}
        scale={0.2}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube079.geometry}
          material={materials["Material.003"]}
        />
      </group>

      <group
        position={[0, 0, 0]}
        rotation={[0, 0, 0]}
        scale={0.2}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube083.geometry}
          material={materials["Material.008"]}
        />
      </group>

      
    {/* TEXT */}

      <group
        position={[0,0, 0]}
        rotation={[0, 0, 0]}
        scale={0.2}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text034.geometry}
          material={materials["Material.015"]}
        >
        </mesh>
      </group>

      <group
        position={[0, 0, 0]}
        rotation={[0, 0, 0]}
        scale={0.2}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text035.geometry}
          material={materials["Material.015"]}
        >
          </mesh>
      </group>

      <group
        position={[0, 0, 0]}
        rotation={[0, 0, 0]}
        scale={0.2}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text034.geometry}
          material={materials["Material.015"]} />
      </group>

      <group
        position={[0, 0, 0]}
        rotation={[0, 0, 0]}
        scale={0.2}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text035.geometry}
          material={materials["Material.015"]}
        >
        </mesh>
      </group>

    </animated.group>
  );
}




export default Model;
