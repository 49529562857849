'use client';
import React, { useRef } from 'react'
import { Mesh, MeshStandardMaterial } from 'three'
import { useGLTF } from "@react-three/drei";
import { GLTF } from "three-stdlib";
import { useSpring, animated } from '@react-spring/three';
import { GroupProps, useFrame } from '@react-three/fiber';
interface ModelProps extends GroupProps {
  active: boolean;
}

export type DreiGLTF = GLTF & {
  nodes: {
    BezierCurve: Mesh;
    BezierCurve002: Mesh;

    Circle001: Mesh;

    Cube001: Mesh;
    Cube002: Mesh;
    Cube003: Mesh;
    Cube004: Mesh;
    Cube005: Mesh;

    Cube063: Mesh;
    Cube064: Mesh;
    Cube065: Mesh;
    Cube066: Mesh;
    Cube093: Mesh;
    Cube094: Mesh;
    Cube095: Mesh;
    Cube097: Mesh;
    Cube108: Mesh;
    Cube108_1: Mesh;
    Cube108_2: Mesh;
    Cube108_3: Mesh;
    Cube108_4: Mesh;

    Text036: Mesh;
    Text037: Mesh;
    Text040: Mesh;
  };
  materials: {
    ["Material"]: MeshStandardMaterial;
    ["Material.008"]: MeshStandardMaterial;
    ["Material.006"]: MeshStandardMaterial;
    ["Material.005"]: MeshStandardMaterial;
    ["Material.007"]: MeshStandardMaterial;
    ["Material.009"]: MeshStandardMaterial;
    ["Material.010"]: MeshStandardMaterial;
    ["Material.011"]: MeshStandardMaterial;
    ["Gold"]: MeshStandardMaterial;
    ["Material.004"]: MeshStandardMaterial;
    ["Material.003"]: MeshStandardMaterial;
    ["Material.001"]: MeshStandardMaterial;
    ["Material.012"]: MeshStandardMaterial;
    ["Material.013"]: MeshStandardMaterial;
    ["Material.015"]: MeshStandardMaterial;
  };
};

export function Model({ active, ...props }: ModelProps) {

  const { nodes, materials } = useGLTF('/images/visual.glb') as DreiGLTF;
  const groupRef = useRef<THREE.Group | null>(null);

  const { rotation } = useSpring({
    to: { rotation: active ? [0, 0, 0] : [0, -1, 0] },
    from: { rotation: [0, -1, 0] },
    config: { mass: 1, tension: 180, friction: 50 }
  });

  const { position } = useSpring({
    to: { position: active ? [0.37, 0, 0.2] : [0.37, 0, 0] },
    from: { position: [0.37, 0, 0] },
    config: { mass: 1, tension: 180, friction: 50 }
  })

  useFrame(() => {
    const [x, y, z] = rotation.get();
    const [posX, posY, posZ] = position.get();
    if (groupRef.current) {
      groupRef.current.rotation.set(x, y, z);
      groupRef.current.position.set(posX, posY, posZ);
    }
  });

  return (

    <animated.group {...props} scale={1} dispose={null} ref={groupRef} >
      <group
        position={[0, 0, 0]}
        rotation={[0, 0, 0]}
        scale={0.2}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.BezierCurve.geometry}
          material={materials["Material.013"]}
        />
      </group>

      <group
        position={[0, 0, 0]}
        rotation={[0, 0, 0]}
        scale={0.2}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.BezierCurve002.geometry}
          material={materials["Material.013"]}
        />
      </group>

      <group
        position={[0, 0, 0]}
        rotation={[0, 0, 0]}
        scale={0.2}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Circle001.geometry}
          material={materials["Material.013"]}
        />
      </group>

      <group
        position={[0, 0, 0]}
        rotation={[0, 0, 0]}
        scale={0.2}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube001.geometry}
          material={materials["Material.010"]}
        />
      </group>

      <group
        position={[0, 0, 0]}
        rotation={[0, 0, 0]}
        scale={0.2}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube002.geometry}
          material={materials["Material.008"]}
        />
      </group>

      <group
        position={[0, 0, 0]}
        rotation={[0, 0, 0]}
        scale={0.2}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube003.geometry}
          material={materials["Material.007"]}
        />
      </group>

      <group
        position={[0, 0, 0]}
        rotation={[0, 0, 0]}
        scale={0.2}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube004.geometry}
          material={materials["Material.009"]}
        />
      </group>

      <group
        position={[0, 0, 0]}
        rotation={[0, 0, 0]}
        scale={0.2}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube005.geometry}
          material={materials["Material.011"]}
        />
      </group>

      <group
        position={[0, 0, 0]}
        rotation={[0, 0, 0]}
        scale={0.2}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube063.geometry}
        >
          <meshBasicMaterial color="white" />
        </mesh>
      </group>

      <group
        position={[0, 0, 0]}
        rotation={[0, 0, 0]}
        scale={0.2}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube064.geometry}
          material={materials["Material.012"]}
        />
      </group>

      <group
        position={[0, 0, 0]}
        rotation={[0, 0, 0]}
        scale={0.2}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube065.geometry}
          material={materials["Material.013"]}
        />
      </group>


      <group
        position={[0, 0, 0]}
        rotation={[0, 0, 0]}
        scale={0.2}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube093.geometry}
          material={materials["Material.015"]}
        />
      </group>


      {/* TEXT */}

      <group
        position={[0, 0, 0]}
        rotation={[0, 0, 0]}
        scale={0.2}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text036.geometry}
          material={materials["Material.013"]}
        />
      </group>

      <group
        position={[0, 0, 0]}
        rotation={[0, 0, 0]}
        scale={0.2}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text037.geometry}
          material={materials["Material.013"]}
        />
      </group>

      <group
        position={[0, 0, 0]}
        rotation={[0, 0, 0]}
        scale={0.2}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text040.geometry}
          material={materials["Material.013"]}
        />
      </group>

    </animated.group>
  );
}




export default Model;
