
'use client';
import React, {useRef, useEffect} from 'react';
import gsap from "gsap";
import ScrollTrigger from 'gsap/ScrollTrigger';
import classes from "./content.module.scss";
import cx from 'classnames';

export type ContentProps = {
    className?: string,
    alignment?: 'left' | 'center' | 'right',
    number?: string,
    title?: string,
    subtitle?: string,
    paragraphs?: string[],
    contain?: boolean,
}

export default function Content(props: ContentProps) {

    const _classes = cx(classes['content'], {
        [classes[`align-${props.alignment}`]] : props.alignment,
        [classes['contain']] : props.contain,
    }, props.className);

    const wrapperRef = useRef<HTMLDivElement>(null);
    const titleText = props.title;

    const titleRef = useRef<HTMLDivElement>(null);
    const subtitleRef = useRef<HTMLDivElement>(null);
    const numberRef = useRef<HTMLDivElement>(null);
    const paragraphRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);

        if (!wrapperRef.current) return;

        const commonRefs = [wrapperRef.current.querySelector('.' + classes['number']), wrapperRef.current.querySelector('.' + classes['subtitle'])].filter(ref => ref);

        gsap.fromTo(wrapperRef.current, 
            { y: -80 }, // Starting position
            {
                y: 30, // Ending position
                scrollTrigger: {
                    trigger: wrapperRef.current,
                    start: "top-=1000",
                    end: "bottom+=200",
                    scrub: true, // Smoothly animate the y property in relation to scroll position
                }
            }
        );


        // Scoped selector function for this component instance
        const scopedSelector = gsap.utils.selector(wrapperRef.current);
        const letters = scopedSelector('.letter');

        // Common animation with stagger for selected refs
        if (commonRefs.length) {
            commonAnimationWithStagger(commonRefs as Element[]);
        }

        // Letter animation within the title, scoped to this component
        if (letters.length) {
            gsap.fromTo(letters, 
                { rotateX: -90, y: 20 },
                {
                    rotateX: 0, stagger: 0.03, y: 0, // Stagger the animation of each letter
                    scrollTrigger: {
                        trigger: wrapperRef.current,
                        start: "top-=800",
                        end: "bottom bottom",
                    }
                }
            );
        }

        // Different effect for paragraphRef
        const paragraphRef = wrapperRef.current.querySelector('.' + classes['paragraphs']);
        if (paragraphRef) {
            animateParagraph(paragraphRef);
        }

        return () => {
            ScrollTrigger.getAll().forEach(trigger => trigger.kill());
        };
    }, [props.title, props.subtitle, props.number, props.paragraphs]);

    function commonAnimationWithStagger(elements: Element[]) {
        gsap.fromTo(elements, 
            { rotateX: 90 }, 
            {
                rotateX: 0,
                delay: 0.6,
                stagger: 0.3, // Stagger the animation of each element by 0.2 seconds
                scrollTrigger: {
                    trigger: wrapperRef.current,
                    start: "top-=800",
                    end: "bottom bottom",
                }
            }
        );
    }

    function animateParagraph(element: Element) {
        gsap.fromTo(element, 
            { opacity: 0, y: 10 }, 
            {
                opacity: 1, y: 0,
                delay: 1,
                duration: 1,
                scrollTrigger: {
                    trigger: wrapperRef.current,
                    start: "top-=800",
                    end: "bottom bottom",
                }
            }
        );
    }

    return (
        <div className={_classes} ref={wrapperRef}>
            <div ref={numberRef} className={classes['number']}>({props.number})</div>
            <div ref={titleRef} className={classes['title']}>
                {titleText ?
                    titleText.split("").map((letter, index) => (
                        letter === " " 
                        ? <span key={index} className="letter" style={{ width: '0.33em', display: 'inline-block' }}>&nbsp;</span> 
                        : <span key={index} className="letter">{letter}</span>
                    ))
                : null
                } 
            </div>
            <div ref={subtitleRef} className={classes['subtitle']}>{props.subtitle}</div>
            {props.paragraphs ?
            <div ref={paragraphRef} className={classes['paragraphs']}>
                {props.paragraphs.map((paragraph, index) => (
                    <p key={index}>{paragraph}</p>
                ))}
            </div>
            : null}
        </div>
    );
}

