'use client';
import React, { useRef } from 'react'
import {Mesh, MeshStandardMaterial} from 'three'
import { useGLTF} from "@react-three/drei";
import { GLTF } from "three-stdlib";
import { useSpring, animated } from '@react-spring/three';
import { GroupProps, useFrame } from '@react-three/fiber';

interface ModelProps extends GroupProps {
  active: boolean;
}

export type DreiGLTF = GLTF & {
  nodes: {
    Cube035: Mesh;
    Cube069: Mesh;
    Cube070: Mesh;
    Cube071: Mesh;
    Cube101: Mesh;

    Cylinder008: Mesh;
    Cylinder009: Mesh;
    Cylinder010: Mesh;

    Text030: Mesh;
    Text031: Mesh;
    Text032: Mesh;
    Text033: Mesh;
  };
  materials: {
    ["Material"]: MeshStandardMaterial;
    ["Material.008"]: MeshStandardMaterial;
    ["Material.006"]: MeshStandardMaterial;
    ["Material.007"]: MeshStandardMaterial;
    ["Gold"]: MeshStandardMaterial;
    ["Material.004"]: MeshStandardMaterial;
    ["Material.002"]: MeshStandardMaterial;
    ["Material.001"]: MeshStandardMaterial;
  };
};

export function Model({ active, ...props }: ModelProps) {

  const { nodes, materials } = useGLTF('/images/development.glb') as DreiGLTF;
  const groupRef = useRef<THREE.Group | null>(null);
  const buttonRef = useRef<THREE.Group>(null);
  const textRef = useRef<THREE.Group>(null);

  const { rotation } = useSpring({
    to: { rotation: active ? [0, 0, 0] : [0, -1, 0]  },
    from: { rotation: [0, -1, 0]},
    config: { mass: 1, tension: 180, friction: 50 }
  });

  const {position} = useSpring({
    to: { position: active ? [-0.38,0,0.2] : [-0.38,0,0]},
    from: {position: [-0.38, 0, 0]},
    config: { mass: 1, tension: 180, friction: 50}
  })

  useFrame(() => {
    const [x, y, z] = rotation.get();
    const [posX, posY, posZ] = position.get();
    if (groupRef.current) {
      groupRef.current.rotation.set(x, y, z);
      groupRef.current.position.set(posX, posY, posZ);
    }
  });

  useFrame((state) => {
    const elapsedTime = state.clock.getElapsedTime();
    // Oscillate scale between 1 and 1.1 over a period of 2 seconds
    const scale = 0.19 + 0.005 * Math.sin(elapsedTime * Math.PI / 1); // Period is 2 seconds (2 * PI for a full sine wave cycle)

    if (buttonRef.current) {
      buttonRef.current.scale.set(scale, scale, scale);
    }
    if (textRef.current) {
      textRef.current.scale.set(scale, scale, scale);
    }
  });
  
  return (
    <>
    <animated.group {...props} scale={1} dispose={null} 
      ref={groupRef} 
      >
      <group
        position={[0, 0, 0]}
        rotation={[0, 0, 0]}
        scale={0.2}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube035.geometry}
        >
        <meshBasicMaterial color="white" />
        </mesh>
      </group>

      <group
        position={[0, 0, 0]}
        rotation={[0, 0, 0]}
        scale={0.2}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube069.geometry}
          material={materials["Material.001"]}
        />
      </group>

      <group
        position={[0, 0, 0]}
        rotation={[0, 0, 0]}
        scale={0.2}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube070.geometry}
          material={materials["Material.004"]}
        />
      </group>

      <group
        position={[0, 0, 0]}
        rotation={[0, 0, 0]}
        scale={0.2}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube071.geometry}
          material={materials["Material.001"]}
        />
      </group>

      <group
        position={[0, 0, 0]}
        rotation={[0, 0, 0]}
        scale={0.2}
        ref={buttonRef}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube101.geometry}
          material={materials["Material.008"]}
        />
      </group>

      {/* CYLINDERS */}
      <group
        position={[0,0, 0]}
        rotation={[0, 0, 0]}
        scale={0.2}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder008.geometry}
          material={materials["Material.007"]}
        />
      </group>
      <group
        position={[0,0, 0]}
        rotation={[0, 0, 0]}
        scale={0.2}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder009.geometry}
          material={materials["Material.008"]}
        />
      </group>
      <group
        position={[0,0, 0]}
        rotation={[0, 0, 0]}
        scale={0.2}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder010.geometry}
          material={materials["Material.006"]}
        />
      </group>
      <group
        position={[0,0, 0]}
        rotation={[0, 0, 0]}
        scale={0.2}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text030.geometry}
          material={materials["Material.001"]}
        />
      </group>
      <group
        position={[0, 0, 0]}
        rotation={[0, 0, 0]}
        scale={0.2}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text031.geometry}
          material={materials["Material.001"]}
        />
      </group>
      <group
        position={[0, 0, 0]}
        rotation={[0, 0, 0]}
        scale={0.2}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text032.geometry}
          material={materials["Material.001"]}
        />
      </group>
      <group
        position={[0, 0, 0]}
        rotation={[0, 0, 0]}
        scale={0.2}
        ref={textRef}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text033.geometry}
          material={materials["Material.001"]}
        />
      </group>
    </animated.group>
    </>);
}




export default Model;
